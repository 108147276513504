/*==========================
08. About Style CSS
==========================*/

.about-content {
  @media #{$xl-device} {
    margin-left: 55px;
    padding-right: 80px;
  }

  ul li {
    margin-bottom: 10px;
  }

  ul li:before {
    font-family: "FontAwesome";
    content: "\f105";
    margin-right: 15px;
  }

  h2 {
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    font-size: 30px;
  }

  .announcementHeading {
    padding: 7px;
    color: #ffffff;
    background-color: #e94d69;
    font-family: "Quicksand", sans-serif !important;
    font-weight: 700 !important;
    font-size: 23px;
  }

  h6 {
    margin-bottom: 13px;
  }

  .about-since {
    color: #484848;
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    position: relative;
    padding-right: 20px;
    margin-bottom: 30px;

    @media #{$sm-device} {
      margin-bottom: 20px;
    }

    &:after {
      background-color: #b4b4b4;
      content: "";
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 110px;
    }
  }

  .btn-about {
    color: $paragraph;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    margin-top: 15px;

    @media #{$sm-device} {
      margin-top: 5px;
    }

    i {
      font-size: 20px;
      line-height: 3px;
      vertical-align: middle;
      transition: 0.3s;
    }

    &:hover {
      i {
        padding-left: 5px;
      }
    }
  }

  &--2 {
    @media #{$xl-device} {
      margin-left: 0;
      padding-right: 40px;
    }
  }
}

.about-thumb {
  overflow: hidden;
  margin-bottom: -90px;

  @media #{$sm-device} {
    margin-bottom: 35px;
  }

  img {
    width: 100%;
    transition: 0.4s;
  }

  &--2 {
    margin-right: -250px;
  }
}

.about-page {
  font-family: "Exo 2", sans-serif;
  color: #848484;
  &-section {
    p {
      font-size: 17px;
      font-weight: 300;
    }
    img {
      border-radius: 20px;
    }
    &-two {
      padding-top: 100px;
      padding-bottom: 100px;
      min-height: 600px;
      background-position: bottom right;
    }
  }
}

.video-button-thumb {
  position: relative;

  &:after {
    content: "";
    background-color: $brandColor;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .btn-play {
    border-radius: 50%;
    background-color: $white;
    color: $brandColor;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    &:hover {
      background-color: $brandColor;
      color: $white;
    }

    i {
      padding-left: 5px;
    }
  }
}
.about-feat-icon {
  font-size: 22px;
  color: $brandColor;
}

.icon-box__icon_ab {
  margin-right: 20px;
  margin-bottom: 0;
  flex-basis: 72px;
  width: 72px;
  height: 60px;
  border: 2px solid $brandColor;
  border-radius: 40px;
  display: flex;
}
.icon-box__icon_ab i {
  margin: auto;
}

.section-about {
  background-image: url("../../img/parallax-lab-image.jpg");
  background-attachment: fixed;
  background-position: center;
  padding-top: 100px;
}

.contact-icon {
  color: #e94d69;
  font-size: 17px;
}
.contact-icon-w {
  color: #ffffff;
  font-size: 17px;
  margin-right: 15px;
}

.basefont {
  color: $brandColor !important;
  &-strong {
    color: $brandColor !important;
    font-weight: 500;
  }
}

.announcementHeadingBlock {
  padding: 7px;
  color: #ffffff;
  background-color: #e94d69;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 700 !important;
  font-size: 27px;
  text-align: center;
}
.announcementHeadingBlock p {
  display: inline;
}
@media (min-width: 768px) {
  .announcementHeadingBlock {
    margin-top: -80px;
    margin-bottom: 30px !important;
  }
  .announcementHeadingBlock p {
    display: block;
    line-height: 1.6rem;
  }
}

.aboutTopText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: #fff;
  height: calc(100vh - 540px);
  max-height: 250px;
}
.aboutTopText h1 {
  color: #e94d69;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 700 !important;
  font-size: 27px;
  color: #e94d69;
  margin-bottom: 0 !important;
  font-size: 40px;
  text-align: center;
}
@media (max-width: 600px) {
  .aboutTopText h1 {
    width: 95%;
    margin: 0 auto;
    font-size: 24px;
  }
}
// #e94d69;

.view_iframe_wrapper {
  margin-top: 100px;
}
.view_iframe {
  width: 100%;
}
.view_iframe h3 {
  font-weight: bold;
}
@media (max-width: 600px) {
  .view_iframe {
    width: 80%;
    margin: 0 auto;
  }
  .view_iframe_wrapper {
    margin-top: 50px;
  }
}

.views360 h5 {
  margin-bottom: 0px !important;
}

.views360 .card-body {
  padding: 10px;
}
